import logo from './logo.svg';
import './themes.css';
import classes from "./app.module.css"
import './App.css';
import React, { useEffect, useState } from 'react';
import Header from './components/headerComponent/header';
import Footer from './components/footerComponent/footer';
import Home from './components/homeComponent/home';
import { Route, Router, Routes, useActionData } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.css"
import ScrollToTop from 'react-scroll-to-top';
import { Contact } from './components/contactusComponent/Contact';
import Gallery from './components/gallery/gallery';
import { ContactPage } from './components/contactus/Contact';
import { Press } from './components/card/Press';
import Discography, { albums } from './components/discographyComponent/discography';
import { AboutMe } from './components/Aboutme/AboutMe';
import { CheckOut } from "./components/checkout/CheckOut";
import { NewPolicy } from "./components/newPolicy/NewPolicy";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink } from 'react-router-dom/dist';
import useSilverHook from './customHooks/customHooks/useSilverHook';
import { Close, CloseOutlined, CloseRounded } from '@mui/icons-material';

function App() {

  const [cardtotal, setcardtotal] = useState(0)
  const [cardstate, setcardState] = useState()
  const [carditems, setcarditems] = useState( () => {

    if ( localStorage.getItem('items')) {
      try {
        if ( JSON.parse(localStorage.getItem('items'))) {

          return  JSON.parse(localStorage.getItem('items') || '[]') || []
        }else{
          // alert('else')
        }
      } catch (error) {
          // alert()
        return []
      }
      return []
    }
    return []
  }

  )
  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setcardState(!cardstate);
  };
  function removecard(card) {
    setcarditems((items) => {
      let n = []
      for (let i = 0; i < carditems?.length; i++) {
        if (carditems[i]?.id != card?.id) {
          n.push(carditems[i])
        }
      }
      return (n.length > 0 ? [...n] : [])
    })
  }

  const ListComponent = ({ anchor }) => {
    const [totalcost, settotalcost] = useState(0)

    useEffect(() => {
      settotalcost(() => {

        let total = 0
        console.log(carditems)
        if (!carditems) {
          return 0
        } else {
          for (let i = 0; i < carditems.length; i++) {
            total += ((carditems[i]?.price || 0) * (carditems[i]?.quantity || 1))

          }
        }
        return total
      })
    }, [cardtotal])
    useEffect(() => {
      localStorage.setItem('items', JSON.stringify(carditems || []))
    }, [carditems])
    return (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className={classes.cartContainer}>
          <div className={classes.cartHeader}>
            <div>SHORPING CART</div>
            <div className={classes.itemsCount}>{cardtotal}</div>
          </div>

          <div className={classes.cartBody}>
            {
              carditems?.map((card) => {
                return (
                  <div key={card?.id} >
                    <div className={classes.listcontainer + ' m-1 my-2 p-1 d-flex align-items-center'}>
                      <div className={classes.img}>
                        <img src={card?.image} />

                      </div>
                      <div className='ms-2'>
                        <div style={{ color: 'cyan' }} >
                          {card?.title}
                        </div>

                        <div className='d-flex ' style={{ fontSize: '.8em' }} >
                          Quantity: <nav className='mx-2' style={{ color: 'cyan' }}>{card?.quantity}</nav>
                        </div>
                      </div>
                      <div className={' d-flex flex-column ms-auto'}>
                        <div onClick={() => removecard(card)} className='ms-auto mb-1' style={{ fontSize: '.7em' }}><CloseOutlined /></div>
                        <div style={{ fontSize: '.8em', color: 'rgba(0,200,200,.7)' }} className='text me-2'>
                          {card?.category}
                        </div>
                        {/* <div className='d-flex m-1'>
                        musics: <nav className='mx-2' style={{ color: 'cyan' }}>{card?.music?.length}</nav>
                      </div> */}
                      </div>
                    </div>
                  </div>)
              })
            }
          </div>
          <div className={classes.cartFooter}>
            <div className={classes.subTotalDisp}>
              <div className={classes.subTotal}>SUBTOTAL:</div>
              <div>{totalcost} CFA </div>
            </div>
            <div className={classes.viewCart}>
              VIEW CART
            </div>
            <div className={classes.checkoutbtn} ><NavLink to={"/checkout"}  >CHECKOUT</NavLink ></div>
          </div>

        </div>
      </Box>
    )
  }

  const clearcard = () => {
    setcardtotal(0)
    setcarditems([])
  }
  function addtocard(album) {
    // console.log('adding',album)
    let sub = true
    for (let i = 0; i < carditems.length; i++) {
      if ((carditems[i]?.id == album?.id)) {
        alert('item already in card')
        sub = false
      }
    }
    if (sub) {
      setcarditems((card) => [...card, album])
    }
  }


  useEffect(() => {
    setcardtotal(carditems?.length)
  }, [carditems])

  return (

    <div style={{backgroundColor:"black"}}>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/press' element={<Press />} />
        <Route path='/discography' element={<Discography cardstate={cardstate} setcardState={setcardState} />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/aboutme' element={<AboutMe />} />
        <Route path='/checkout' element={<CheckOut carditems={carditems} cardtotal={cardtotal} cardstate={cardstate} setcardState={setcardState} />} />
        <Route path='/policy' element={<NewPolicy cardtotal={cardtotal} addtocard={addtocard} setcardtotal={setcardtotal} toggleDrawer={toggleDrawer} />} />

      </Routes>
      <ScrollToTop className="scrollToTopBtn" smooth />

      <Footer />
      <div>
        <React.Fragment>
          {/* <Button onClick={toggleDrawer()}>{"RIGHT"}</Button> */}
          <SwipeableDrawer
            anchor={"right"}
            open={cardstate}
            onClose={toggleDrawer()}
            onOpen={toggleDrawer()}
          >
            <ListComponent anchor={"hello"} />
          </SwipeableDrawer>
        </React.Fragment>
      </div>

    </div>

  );
}

export default App;
