
import "./press.css";
import press from "../../assets/images/tino.jpeg"

export const Card = ({tit,body,txt,img}) => {

    return(
        <div style={{backgroundColor:"black"}}>
            <div style={{backgroundColor:"black",color:"lightgray"}} className="container">
                <div style={{display:"flex", padding:"50px"}} className={"main gap-3"}>
                    <img src={img} width={"20%"}/>
                    <div>
                        <h2>{tit}</h2>
                        <p>{body}</p>
                        <p>{txt}</p>

                    </div>

                </div>
                <br/>
                <div style={{width:"80%",marginLeft:"28px",color:"white"}} className={"line"}>
                    <hr/>
                    <br/>
                </div>

            </div>

        </div>


    )

}