import "./checkout.css";

import * as React from "react";

export const CheckOut = (carditems, cardstate, setcardstate, cardtotal) => {
    const [totalcost, settotalcost] = React.useState(0)
    React.useEffect(() => {

        window.scrollTo(0, 0)

        return () => {

        }
    }, [])

    React.useEffect(() => {

        settotalcost(() => {

            let total = 0
            if (!carditems) {
                return 0
            } else {

                for (let i = 0; i < carditems?.carditems.length; i++) {
                 
                    total += ((carditems?.carditems[i]?.price || 0) * (carditems[i]?.quantity || 1))

                }
            }
            return total
        })
    }, [cardtotal, carditems])
    return (
        <div style={{ backgroundColor: "black", color: "whitesmoke", paddingBottom: "20px", paddingTop: "20px" }}>
            <div className={"container"} style={{ textAlign: "center" }}>
                <div >
                    <p>
                        Returning customer? <span className="a-link">Click here to login</span>
                    </p>
                    <p>
                        Have a coupon? <span className="a-link">Click here to enter your code</span>
                    </p>
                </div>

                <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "50px" }} className={"checkout"}>
                    <form className="form-field">
                        <div style={{ display: "flex", gap: "30px" }}>
                            <div style={{ gap: "10px", textAlign: "start", width: "100%" }} >
                                <label>First name *</label>
                                <input type={"text"} className={"form-control"} placeholder={"enter firstname"} />
                            </div>
                            <div style={{ gap: "10px", textAlign: "start", width: "100%" }}>
                                <label>Last name *</label>
                                <input className={"form-control"} type={"text"} placeholder={"enter firstname"} />
                            </div>
                        </div>
                        <div style={{ gap: "20px", textAlign: "start", width: "100%" }}>
                            <label style={{ gap: "10px", textAlign: "start" }} >Company name (optional)</label>
                            <input className={"form-control"} placeholder="Company name" />
                            <label style={{ gap: "10px", textAlign: "start" }} >Country / Region *</label>
                            <input className={"form-control"} placeholder="Country / Region" />
                            <label style={{ gap: "10px", textAlign: "start" }} >Street address *</label>
                            <input className={"form-control"} placeholder="Street address" />
                            <input className={"form-control"} />
                            <label style={{ gap: "10px", textAlign: "start" }} >Town / City *</label>
                            <input className={"form-control"} placeholder="Town / City" />
                            <label style={{ gap: "10px", textAlign: "start" }} >State *</label>
                            <input className={"form-control"} placeholder="State" />
                            <label style={{ gap: "10px", textAlign: "start" }} >ZIP Code *</label>
                            <input className={"form-control"} placeholder="ZIP Code" />
                            <label style={{ gap: "10px", textAlign: "start" }} >Phone *</label>
                            <input className={"form-control"} placeholder="Phone" />
                            <label style={{ gap: "10px", textAlign: "start" }} >Email address *</label>
                            <input className={"form-control"} placeholder="Email address" />
                            <div style={{ display: "flex", gap: "10px" }}>
                                <input type="checkbox" />
                                <p style={{ marginTop: "4px", color: "grey" }}>create an account?</p>
                            </div>
                            <h1>Additional Information</h1>
                            <p>Order Notes (Optional)</p>
                            <div className="form-group">
                                <textarea name="message" className="form-control" id="message" cols="30" rows="8" placeholder="Message" style={{ backgroundColor: "transparent", color: "white" }}></textarea>
                            </div>

                        </div>
                    </form>
                    <div style={{ backgroundColor: "lightgrey", borderRadius: "10px" }} className={"sec2"}>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>product</h6>
                            <h6>subtotal</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }} className="d-flex row">
                            {carditems?.carditems?.map((item)=>{
                                return <div className="border p-1 col m-2" style={{borderRadius:'2px',cursor:'pointer',width:'max-content'}}>{item?.title}</div>
                            })}
                            
                            <hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>New Policy x3</h6>
                            <h6>CFA {''}</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>SubTotal</h6>
                            <h6>CFA {totalcost}</h6>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px", color: "black" }}>
                            <h6>Total</h6>
                            <h4>CFA {totalcost}</h4>


                        </div>
                        <div style={{ padding: "0px 20px 0px 20px" }}><hr style={{ color: "black" }} /></div>
                        <div style={{ textAlign: "start", padding: "10px" }}>
                            <p style={{ color: "black" }}>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                        </div>
                        <div style={{ padding: "0px 20px 10px 20px" }}>
                            <button className="btn card-btn" style={{ marginBottom: "10px", borderRadius: "10px", backgroundColor: "cyan", width: "100%", padding: "10px" }}>Place Order</button>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    )
}