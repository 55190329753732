
import "./policy.css"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import imh from '../../assets/images/img.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { albums } from "../discographyComponent/discography";

export function getalbum(id) {

    for (let i = 0; i < albums.length; i++) {
        if (albums[i]?.id == id) {
            return albums[i]
        }
    }
    console.log('failed')
    return albums[0]
}
export const NewPolicy = ({ toggleDrawer, cardtotal, setcardtotal, addtocard }) => {
    const additem = () => {
        setcardtotal(cardtotal + 1)
    }
    const [albumparam, setalbumparam] = useSearchParams()
    const location = useLocation()
    const [qty, setqty] = useState(1)
    const [selected, setselected] = useState(() => albumparam.get('album') || '')
    const [album, setalbum] = useState(getalbum(selected))
    const [nextlink] = useState(() => {
        for (let i = 0; i < albums.length; i++) {
            if (i == (albums.length - 1) && album?.id == albums[i]?.id) {

                return undefined
            } else if (album?.id == albums[i]?.id) {
                return '/policy?album=' + albums[i + 1]?.id
            }
        }
    })
    const [prevlink] = useState(() => {
        for (let i = 0; i < albums.length; i++) {
            if (i == 0 && album?.id == albums[i]?.id) {
                return undefined
            } else if (album?.id == albums[i]?.id) {
                return '/policy?album=' + albums[i - 1]?.id
            }
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])
    return (
        <div style={{ backgroundColor: "black", color: "white", padding: "40px" }}>
            <div className="container">
                <div style={{ display: "flex", gap: "15px", justifyContent: "space-between" }}>
                    <div className={'policy-menu'}>
                        <p><NavLink style={{ textDecoration: 'none', color: 'cyan' }} to={'/'}>Home</NavLink> /</p>
                        <p><NavLink style={{ textDecoration: 'none', color: 'cyan' }} to={'/discography'}> Digital Download </NavLink>/</p>
                        <p><NavLink style={{ textDecoration: 'none', color: 'cyan' }} to={'/policy'}> New Policy</NavLink> </p>

                    </div>

                    <div className="p-controls">
                        {prevlink != undefined ? <div className="d-flex btn1"><a style={{ textDecoration: 'none', color: 'cyan', display: 'flex' }} href={prevlink}><ArrowLeftIcon /> <p>PREVIOUS</p></a></div>
                            : <div className="d-flex btn1 text-muted"><ArrowLeftIcon /> <p>PREVIOUS</p></div>}
                        <br />
                        <p>|</p>
                        {nextlink != undefined ? <div className="d-flex btn1"><a style={{ textDecoration: 'none', color: 'cyan', display: 'flex' }} href={nextlink}>
                            <p>Next</p> <ArrowRightIcon />
                        </a></div>
                            : <div className="d-flex text-muted btn1">
                                <p>Next</p> <ArrowRightIcon />
                            </div>}

                    </div>


                </div>
                <div>
                    <div className="n-policy">
                        <img src={album?.image} width={"40%"} />
                        <div className="n-p-content">

                            <h3>{album?.title}</h3>
                            <p>{album?.price}CFA </p>
                            <div style={{ display: "flex", gap: "10px" }}>
                                <div className="d-flex py-2 align-item-center justify-content-around" style={{ maxWidth:'180px', border: "1px solid cyan",height:"100%", display: "flex", width: "100%", alignItems: "center", borderRadius: "50px" }}>
                                    <div onClick={()=>setqty((q)=>q-1>0?q-1:1)} className=""><RemoveIcon /></div>

                                    <div className="">{qty}</div>
                                    <div onClick={()=>setqty((q)=>q+1)} className=""><AddIcon /></div>

                                </div>
                                <button onClick={toggleDrawer()} className={"btn "} style={{ width: "100%", borderRadius: "50px" }}><div style={{ textDecoration: "none", color: "black" }} onClick={() => { addtocard({...album,quantity:qty}) }}>Add To Chart</div></button>
                            </div><br />
                            <div style={{ display: "flex", gap: "5px", color: "gray" }}>
                                <p><FavoriteBorderIcon /></p>
                                <p>Add to Wishlist</p>

                            </div>
                            <p>Category: <span style={{ color: "grey" }}>{album?.category}</span></p>

                        </div>
                    </div>

                </div>

            </div>



        </div>
    )
}