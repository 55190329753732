import classes from './style.module.css'
import './style.css'
import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import img1 from "../../assets/images/img_1.png"
import img2 from "../../assets/images/img_2.png"
import img3 from "../../assets/images/hustle and flow.jpg"
// import sound from '../../assets/omahlay.mp3'
import { Album, ArtTrack, Close, Download, LibraryMusic, MusicNote, Pause, PauseCircleSharp, PlayArrow, Shop, ShoppingBag, ShoppingCart, Stop } from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles

// joy and pain
import birthday from '../../assets/music/joy and pain mp3/birthday.mp3'
import doas from '../../assets/music/joy and pain mp3/do-as-you-do.mp3'
import falling from '../../assets/music/joy and pain mp3/falling.mp3'
import isover from '../../assets/music/joy and pain mp3/is-over.mp3'
import marryme from '../../assets/music/joy and pain mp3/marry-me.mp3'
import lavie from '../../assets/music/joy and pain mp3/pour-la-vie.mp3'
import  shutle from '../../assets/music/joy and pain mp3/schutle-es-original.-wav.mp3'
import whatisluv from '../../assets/music/joy and pain mp3/what-is-luv.mp3'


// listen
import BrodaLuv from '../../assets/music/1. Weiser _ Broda Luv -Prod. By DEA The Busker.mp3'
import Gimmi from '../../assets/music/2. Weiser _ Gimmi That -Prod. By DEA The Busker.mp3'
import Iwana from '../../assets/music/3. Weiser _ I wanna be with You -Miexed By DEA The Busker.mp3'
import LaMauf from '../../assets/music/4. Weiser _ La Meuf De Mes Reves -Prod. By DEA The Busker.mp3'
import Owee from '../../assets/music/5. Weiser oweThe Busker.mp3'
import Party from '../../assets/music/6. Weiser _ Party - prod by DEA the Busker.mp3'
import Searching from '../../assets/music/7. Weiser _ Searching -Prod. By DEA The Busker.mp3'
import Senio from '../../assets/music/8. Weiser _ Senio Rita -Prod. By DEA The Busker.mp3'
import Shemine from '../../assets/music/9. Weiser _ She\'s Mine Prod. By DEA The Busker.mp3'


// hustle and flow
import anybody from '../../assets/music/hustle and flow mp3/anybody.mp3'
import fall from '../../assets/music/hustle and flow mp3/fall.mp3'
import faraway from '../../assets/music/hustle and flow mp3/far away.mp3'
import hustle from '../../assets/music/hustle and flow mp3/hustle.mp3'
import manmust from '../../assets/music/hustle and flow mp3/man must hussle  master.mp3'
import nevergiveup from '../../assets/music/hustle and flow mp3/never give up.mp3'
import official from '../../assets/music/hustle and flow mp3/official.mp3'
import papa from '../../assets/music/hustle and flow mp3/papa.mp3'
import partir from '../../assets/music/hustle and flow mp3/partir.mp3'
import partywithyou from '../../assets/music/hustle and flow mp3/party with you.mp3'
import wakawaka from '../../assets/music/hustle and flow mp3/waka-waka.mp3'
import 'swiper/css';
import ReactPlayer from 'react-player';

export const albums = [
      {
            id: 'a1',
            image: img1,
            title: 'JOY & PAIN',
            paragrahText: 'JOY & PAIN is a 9 track project with a blend and mixture of both sides of the coin ie expressing emotions of Love, care, comfort and pain. Two great producers from Cameroon worked on the project',
            paragrahText1: 'YouTube or Audiomack',
            downloadText: 'Download',
            linkHeader: '/policy',
            price: 100,
            category: 'Digital Download',
            music: [
                  {
                        id: 'm1',
                        title: 'SCHUTLE ES ',
                        duration: '',
                        new: true,
                        music: BrodaLuv,
                        audio: new Audio(shutle)
                  },
                  {
                        id: 'm2',
                        title: 'FALLING',
                        duration: '',
                        new: true,
                        music: Gimmi,
                        audio: new Audio(falling)
                  },
                  {
                        id: 'm3',
                        title: 'DO AS WE DO',
                        duration: '',
                        new: true,
                        music: Iwana,
                        audio: new Audio(doas)
                  },
                  {
                        id: 'm4',
                        title: 'MARRY ME',
                        duration: '',
                        new: true,
                        music: LaMauf,
                        audio: new Audio(marryme)
                  },
                  {
                        id: 'm5',
                        title: 'BIRTHDAY',
                        duration: '',
                        new: true,
                        music: Owee,
                        audio: new Audio(birthday)
                  },
                  {
                        id: 'm6',
                        title: 'POUR LA VIE',
                        duration: '',
                        new: true,
                        music: Party,
                        audio: new Audio(lavie)
                  },
                  {
                        id: 'm7',
                        title: "WHAT'S LUV",
                        duration: '',
                        new: true,
                        music: Searching,
                        audio: new Audio(whatisluv)
                  },
                  {
                        id: 'm8',
                        title: "IT'S OVER",
                        duration: '',
                        new: true,
                        music: Senio,
                        audio: new Audio(isover)
                  },
                  // {
                  //       id: 'm9',
                  //       title: 'MY LIFE',
                  //       duration: '',
                  //       new: true,
                  //       music: Shemine,
                  //       audio: new Audio()
                  // }
            ]
            // music: [
            //       {
            //             id: 'm1',
            //             title: 'SCHUTLE ES',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm2',
            //             title: 'FALLING',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm3',
            //             title: 'DO AS WE DO',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm4',
            //             title: 'MARRY ME',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm5',
            //             title: 'BIRTHDAY',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm6',
            //             title: 'POUR LA VIE',
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm7',
            //             title: "WHAT'S LUV",
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm8',
            //             title: "IT'S OVER",
            //             duration: '',
            //             new: true
            //       },
            //       {
            //             id: 'm9',
            //             title: 'MY LIFE',
            //             duration: '',
            //             new: true
            //       }
            // ]
      },
      {
            id: 'a2',
            image: img2,
            title: 'Listen',
            paragrahText: 'Listen is about listening to your heart, listening to your love ones/surrounding and listening to your conscience. Every lover of any musical genre will appreciate this project cuz the singer took that into consideration as he moves from RnB, afrobeats, reggae, reggea dancehall, soul etc plus a creole of languages ie English, French and Pidgin. He features Cameroonian rapper Skiezy Ayenkea on one of the songs plus additional backup vocals. All songs on the project are produced, mixed and mastered by DEA The Busker.',
            paragrahText1: 'YouTube or Audiomack',
            downloadText: 'Download',
            category: 'Digital Download',
            linkHeader: '/policy',
            price: 100,
            music: [
                  {
                        id: 'm1',
                        title: 'Broda Luv',
                        duration: '',
                        new: true,
                        music: BrodaLuv,
                        audio: new Audio(BrodaLuv)
                  },
                  {
                        id: 'm2',
                        title: 'Gimmi That',
                        duration: '',
                        new: true,
                        music: Gimmi,
                        audio: new Audio(Gimmi)
                  },
                  {
                        id: 'm3',
                        title: 'I Wanna be with You',
                        duration: '',
                        new: true,
                        music: Iwana,
                        audio: new Audio(Iwana)
                  },
                  {
                        id: 'm4',
                        title: 'La Meuf De Mes Reves',
                        duration: '',
                        new: true,
                        music: LaMauf,
                        audio: new Audio(LaMauf)
                  },
                  {
                        id: 'm5',
                        title: 'Owée Owée',
                        duration: '',
                        new: true,
                        music: Owee,
                        audio: new Audio(Owee)
                  },
                  {
                        id: 'm6',
                        title: 'Party',
                        duration: '',
                        new: true,
                        music: Party,
                        audio: new Audio(Party)
                  },
                  {
                        id: 'm7',
                        title: "Searching",
                        duration: '',
                        new: true,
                        music: Searching,
                        audio: new Audio(Searching)
                  },
                  {
                        id: 'm8',
                        title: "Senio Rita",
                        duration: '',
                        new: true,
                        music: Senio,
                        audio: new Audio(Senio)
                  },
                  {
                        id: 'm9',
                        title: 'She\'s Mine Prod',
                        duration: '',
                        new: true,
                        music: Shemine,
                        audio: new Audio(Shemine)
                  }
            ]
      },
      {
            id: 'a3',
            image: img3,
            title: 'Listen',
            paragrahText: 'Cameroonian diaspora based artiste Weiser serves the General public with his third studio album titled HUSTLE & FLOW. This album is a twelve track project that has a mixture of songs to Motivate, encourage, love, care, heartbreak etc. \n' +
                '\n' +
                '\n' +
                'The tracklist is made up of two parts. One of the parts which is called HUSTLE has songs like Hustle, Never Give Up, Man Get to Hustle, Fall, Waka Waka, Official and the other part title FLOW got titles like Far Away, Partir, flex, Party, Anybody and papa. \n' +
                '\n' +
                '\n' +
                'In the project, Weiser features with Cameroonian prolific rapper, singer and song writer Skiezy Ayenkea on the tracks Partir and Papa. Three producers worked on this project ie Mender who produced and mixed track 3, 6, 7, 10, 11, DEA The Busker  track 4, 5, 8, 12 and Kumi 1, 2, 9.\n' +
                '\n' +
                'Thank You for Listening!!!',
            paragrahText1: 'YouTube or Audiomack',
            downloadText: 'Download',
            category: 'Digital Download',
            linkHeader: '/policy',
            price: 100,
            music: [
                  {
                        id: 'm1',
                        title: 'HUSTLE',
                        duration: '',
                        new: true,
                        music: BrodaLuv,
                        audio: new Audio(hustle)
                  },
                  {
                        id: 'm2',
                        title: 'NEVER GIVE UP',
                        duration: '',
                        new: true,
                        music: Gimmi,
                        audio: new Audio(nevergiveup)
                  },
                  {
                        id: 'm3',
                        title: 'MAN GET TO HUSTLE',
                        duration: '',
                        new: true,
                        music: Iwana,
                        audio: new Audio(manmust)
                  },
                  {
                        id: 'm4',
                        title: 'FALL',
                        duration: '',
                        new: true,
                        music: LaMauf,
                        audio: new Audio(fall)
                  },
                  {
                        id: 'm5',
                        title: 'WAKA WAKA',
                        duration: '',
                        new: true,
                        music: Owee,
                        audio: new Audio(wakawaka)
                  },
                  {
                        id: 'm6',
                        title: 'OFFICIAL',
                        duration: '',
                        new: true,
                        music: Party,
                        audio: new Audio(official)
                  },
                  {
                        id: 'm7',
                        title: "FAR AWAY",
                        duration: '',
                        new: true,
                        music: Searching,
                        audio: new Audio(faraway)
                  },
                  {
                        id: 'm8',
                        title: "PARTIR FT SKIEZY.A",
                        duration: '',
                        new: true,
                        music: Senio,
                        audio: new Audio(partir)
                  },
                  {
                        id: 'm9',
                        title: 'FLEX',
                        duration: '',
                        new: true,
                        music: Shemine,
                        audio: new Audio()
                  },
                  {
                        id: 'm9',
                        title: 'PARTY WITH YOU',
                        duration: '',
                        new: true,
                        music: Shemine,
                        audio: new Audio(partywithyou)
                  },
                  {
                        id: 'm9',
                        title: 'ANYBODY',
                        duration: '',
                        new: true,
                        music: Shemine,
                        audio: new Audio(anybody)
                  },
                  {
                        id: 'm9',
                        title: 'PAPA FT SKIEZY.A ',
                        duration: '',
                        new: true,
                        music: Shemine,
                        audio: new Audio(papa)
                  }
            ]
      }
]


function Discography({ cardstate, setcardstate }) {
      const [playcont, setplaycont] = useState(false)
      const [playing, setplaying] = useState(false)

      const AlbumComponent = ({ album, image, paragrahText, paragrahText1, downloadText, linkHeader }) => {
            const [displaymusic, setdisplaymusic] = useState(false)

            const [current, setcurrent] = useState('')
            function toogleplay() {
                  setdisplaymusic(!displaymusic)
            }


            // handle all promise rejections
            window.onunhandledrejection = function (event) {
                  console.log(`Reason: ${event.reason}`,
                        ` Return value: ${event.returnValue}`
                  );
            };

            let url = '../../assets/music/omahlay.mp3'
            let piano = document.getElementById('piano')
            let stats = document.getElementById('stats')
            let mouseDown = false;
            let sounds = {
                  a: url,
                  b: "https://freesound.org/data/previews/448/448565_9311684-lq.mp3",
                  c: "https://freesound.org/data/previews/448/448540_9311684-lq.mp3",
                  d: "https://freesound.org/data/previews/448/448600_9311684-lq.mp3",
            }
            // preload audio files crossorigin
            let promises = [];
            Object.keys(sounds).forEach(s => {
                  promises.push(new Promise((resolve, reject) => {
                        let url = sounds[s];
                        sounds[s] = new Audio();
                        sounds[s].addEventListener('canplaythrough', resolve, false);
                        sounds[s].src = url;
                  }));
            });

            Promise.all(promises).then(() => {
                  if (stats) {

                        stats.innerText = `All audio files loaded!  Drag mouse over the keys 😋`;
                        stats.style.color = 'green';
                        init();
                  }
            }).catch(e => {
                  if (stats) {

                        console.log('error loading audio files: ', e);
                        stats.innerText = 'Error loading audio files, see console logs.'
                  }
            });
            const testmusic = new Audio()
            function play() {
                  setplaying(true)
                  testmusic.play()
            }
            function pause() {
                  setplaying(false)
                  testmusic.pause()
            }
            function init() {
                  if (piano) {
                        Object.keys(sounds).forEach(s => {
                              document.querySelectorAll(`.${s}`).forEach(k => {
                                    k.addEventListener('mousedown', () => {
                                          sounds[s].play();
                                    });

                                    k.addEventListener('mouseenter', () => {
                                          if (mouseDown)
                                                sounds[s].play();
                                    });

                                    k.addEventListener('mouseup', () => {
                                          sounds[s].currentTime = 0;
                                          sounds[s].pause();
                                    });
                                    k.addEventListener('mouseleave', () => {
                                          sounds[s].currentTime = 0;
                                          sounds[s].pause();
                                    });
                              })
                        });
                  }
            }
            if (piano) {
                  piano.addEventListener('mousedown', () => mouseDown = true);
                  piano.addEventListener('mouseup', () => mouseDown = false);
                  piano.addEventListener('mouseleave', () => mouseDown = false);
            }
            useEffect(() => {

                  let url = '../../assets/music/omahlay.mp3'
                  let piano = document.getElementById('piano')
                  let stats = document.getElementById('stats')
                  let mouseDown = false;
                  let sounds = {
                        a: url,
                        b: "https://freesound.org/data/previews/448/448565_9311684-lq.mp3",
                        c: "https://freesound.org/data/previews/448/448540_9311684-lq.mp3",
                        d: "https://freesound.org/data/previews/448/448600_9311684-lq.mp3",
                  }
                  // preload audio files crossorigin
                  let promises = [];
                  Object.keys(sounds).forEach(s => {
                        promises.push(new Promise((resolve, reject) => {
                              let url = sounds[s];
                              sounds[s] = new Audio();
                              sounds[s].addEventListener('canplaythrough', resolve, false);
                              sounds[s].src = url;
                        }));
                  });

                  Promise.all(promises).then(() => {
                        if (stats) {

                              stats.innerText = `All audio files loaded!  Drag mouse over the keys 😋`;
                              stats.style.color = 'green';
                              init();
                        }
                  }).catch(e => {
                        if (stats) {

                              console.log('error loading audio files: ', e);
                              stats.innerText = 'Error loading audio files, see console logs.'
                        }
                  });

                  function init() {
                        if (piano) {
                              Object.keys(sounds).forEach(s => {
                                    document.querySelectorAll(`.${s}`).forEach(k => {
                                          k.addEventListener('mousedown', () => {
                                                sounds[s].play();
                                          });

                                          k.addEventListener('mouseenter', () => {
                                                if (mouseDown)
                                                      sounds[s].play();
                                          });

                                          k.addEventListener('mouseup', () => {
                                                sounds[s].currentTime = 0;
                                                sounds[s].pause();
                                          });
                                          k.addEventListener('mouseleave', () => {
                                                sounds[s].currentTime = 0;
                                                sounds[s].pause();
                                          });
                                    })
                              });
                        }
                  }
                  if (piano) {
                        piano.addEventListener('mousedown', () => mouseDown = true);
                        piano.addEventListener('mouseup', () => mouseDown = false);
                        piano.addEventListener('mouseleave', () => mouseDown = false);
                  }


            }, [])

            var handleplay = async () => {
                  var path = url
                  // var audio = new Audio(path);
                  // audio.play();
                  // console.log(audio);
                  // return audio.play();
                  var importRes = await import(path);
                  var audio = new Audio(importRes.default);
                  audio.type = "audio/mp3";
                  try {
                        await audio.play();
                        console.log("Playing audio" + audio);
                  } catch (err) {
                        console.log("Failed to play, error: " + err);
                  }
            };
            return (
                  <div key={image + Math.random(2).toString()}>



                        <div className={classes.album + ' '}>

                              <div className={classes.imageSection}><img src={image} /></div>
                              <div className={classes.textSection}>
                                    <div className={classes.textSectionParagraph}>{paragrahText}</div>
                                    <br />
                                    <div className={classes.textSectionLink + ' mb-3'}>{paragrahText1}</div>

                              </div>
                              {/* <div className='w-100'>
                                    <ArtTrack/>
                              </div> */}
                              <div className={classes.buttonSection}>
                                    <div className={classes.button}><NavLink to={linkHeader + "?album=" + album?.id} style={{ textDecoration: "none", color: "black" }}>{downloadText}</NavLink></div>
                                    <div onClick={toogleplay} className={classes.button2 + ' p-2 mx-2'}>
                                          <NavLink style={{ textDecoration: "none",color:"black" }}>
                                                <PlayArrow />
                                          </NavLink>
                                    </div>

                              </div>
                              <div className='w-100 '>

                                    <div className={(classes.discography)} style={{ height: (displaymusic ? 'auto' : '0px') }}>
                                          {/* <div className={(classes.discography+(displaymusic?' expand':' collapsed'))}> */}
                                          <div className='d-flex  my-3'>

                                                <div onClick={() => setplaycont(true)} className={((playcont ? classes.active : '') + " " + classes.headtext + ' mx-2')}>
                                                      Music
                                                </div>
                                                <div className='ms-auto me-2' ><MusicNote /></div>
                                                <div onClick={toogleplay}><Close /></div>
                                          </div>
                                          <div className='w-100  mx-auto  p-1'>


                                                {
                                                      album.music.map((m) => <Music to={linkHeader + "?album=" + album?.id} current={current} setcurrent={setcurrent} handleplay={handleplay} music={m} />)
                                                }

                                          </div>


                                    </div>


                              </div>
                        </div>
                  </div>
            )
      }
      function
            Music({ to, music, current, setcurrent, handleplay }) {
            const [playing, setplaying] = useState(false)
            const [testmusic] = useState(new Audio(music?.music))
            const [duration, setduration] = useState(((Math.floor((music?.audio?.duration / 60) * 10) / 10)) || 0)
            const [progress, setprogress] = useState(0)
            music?.audio?.addEventListener("timeupdate", function () {
                  var currentTime = music?.audio?.currentTime;
                  var duration = music?.audio?.duration;
                  setprogress(Math.floor(((currentTime) + .25) / (duration) * 100) || 0)
                  // $('.hp_range').stop(true,true).animate({'width':(currentTime +.25)/duration*100+'%'},250,'linear');
            });
            function play() {
                  setcurrent(music?.id)
                  setplaying(true)
                  // testmusic.play()
                  music?.audio?.play()
            }
            function pause() {
                  // testmusic.pause()
                  music?.audio?.pause()
                  setplaying(false)
            }
            function stop() {
                  music?.audio?.load()
                  setplaying(false)
                  // setcurrent('')
                  if (playing) {
                        setcurrent('')
                  }
            }
            useEffect(() => {
                  if (current != music?.id) {
                        // testmusic?.load()
                        stop()
                  } else {
                        setplaying(true)
                  }
            }, [current])
            function ran() {
                  return Math.floor(Math.random() * 255)
            }
            return (
                  <div className={classes.albumprev + ' mx-auto m-1 d-flex align-items-center'} style={playing ? { boxShadow: '0 0 2px rgb(' + ran() + ' ' + ran() + ' ' + ran() + ')' } : {}}>
                        <div className={classes.albumprevimg + '  ms-1'}>
                              {/* <img src={img1} /> */}
                              <Album className={classes.img + ' '} />
                        </div>
                        <div className={classes.details + ' ms-2'}>
                              <div className='d-flex'>
                                    <div>
                                          {music?.title}
                                    </div>
                                    {music?.new ? <div className={classes.new + ' ms-2'}>
                                          NEW
                                    </div> : ''}
                              </div>
                              <div className='d-flex'>
                                    {/* <nav className='me-1'>12</nav> songs */}
                                    {duration + ' '} mins
                              </div>

                        </div>
                        <div className='m-2  ms-auto d-flex'>
                              {
                                    playing ? <><div onClick={pause} className={classes.icon + ' m-2'} style={{ border: 'solid 1.1px rgb(' + ran() + ' ' + ran() + ' ' + ran() + ')' }}>
                                          <Pause />
                                          <div></div>
                                          <div></div>
                                          <div></div>


                                    </div>
                                          {current == music?.id && <div onClick={stop} className={classes.icon + ' m-2'}>
                                                <Stop />


                                          </div>}  </> : <> <div onClick={play}  className={classes.icon + ' m-2 '}>
                                                <PlayArrow />

                                          </div>
                                          {current == music?.id && <div onClick={stop} className={classes.icon + ' m-2'}>
                                                <Stop />


                                          </div>}
                                    </>
                              }
                              <div>
                                    <NavLink className={classes.icon + ' m-2'} style={{ color: "cyan" }} to={to}><ShoppingCart /> </NavLink>

                              </div>
                              {/* <div className={classes.icon + ' m-2'}>
                                    <LibraryMusic />
                              </div> */}
                        </div>
                        <div className={classes.progressCont}>
                              <div className={classes.progress} style={{ width: progress + '%' }}></div>
                        </div>
                  </div>
            )
      }
      // function Albumplaylist() {
      //       const [opened, setopened] = useState(false)
      //       function toogle() {
      //             setopened(!opened)
      //       }
      //       return (
      //             <div className={classes.albumcnt}>
      //                   <div className={' m-1 d-flex align-items-center'}>
      //                         <div className={classes.albumprevimg1 + '  ms-1'}>
      //                               <img src={img1} />
      //                               <div className={classes.desc + ' mx-auto pt-3'}>
      //                                     <div className={classes.details + ' ms-2'}>
      //                                           <div>
      //                                                 first album
      //                                           </div>
      //                                           <div className='d-flex'>
      //                                                 <nav className='me-1'>12</nav> songs
      //                                           </div>

      //                                     </div>
      //                                     <div className='m-2 d-flex align-items-center'>
      //                                           <div className={classes.icon + ' m-2'}>

      //                                                 <Download />
      //                                           </div>
      //                                           <div onClick={toogle} className={classes.icon + ' m-2'}>
      //                                                 {!opened ? <PlayArrow /> :
      //                                                       <Pause />}

      //                                           </div>
      //                                           <div className={classes.icon + ' m-2'}>
      //                                                 <ShoppingBag />
      //                                           </div>
      //                                           <div className='ms-3'>new</div>
      //                                     </div>
      //                               </div>
      //                         </div>

      //                   </div>
      //                   {opened && <div>

      //                         <Music />
      //                         <Music />
      //                   </div>}
      //             </div>
      //       )
      // }
      React.useEffect(() => {

            // window.scrollTo(0, 0)

            return () => {

            }
      }, [])

      return (
            <div className={classes.container}>
                  <div className='container' style={{ textAlign: "center", marginTop: "20px" }} >
                        <h1>Discography</h1>
                  </div>
                  <div className={classes.albums}>

                        {albums.map(
                              (album) =>

                                    <AlbumComponent album={album} key={album.image + Math.random.toString()} image={album.image} paragrahText={album.paragrahText} paragrahText1={album.paragrahText1} downloadText={album.downloadText} linkHeader={album.linkHeader + ""} />


                        )}

                  </div>
                  {/* <Audio src='https://freesound.org/data/previews/448/448540_9311684-lq.mp3'/> */}


            </div>
      )
}

export default Discography