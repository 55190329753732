import {Card} from "../press/Press";
import img1 from "../../assets/images/img_1.png"
import img2 from "../../assets/images/img_2.png"
import img3 from "../../assets/images/hustle and flow.jpg"

export const Press=()=>{
    return(
        <>
            <Card tit={"UPDATE: PRESS RELEASE"} body={"20/08/2022 18/07/2023.by weiser. No comments yet"} img={img1}/>
            <Card tit={"EP RELEASE DATE"} body={"11/08/2022 18/07/2023.by weiser"} txt={"Set for public release on the 11th of September , 2022 – LISTEN EP is currently available for preorder…"} img={img2}/>
            <Card tit={"EP RELEASE DATE"} body={"No comment yet"} txt={""} img={img3}/>
        </>
    )
}