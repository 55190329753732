import PhotoAlbum from "react-photo-album";
import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import img1 from "../../assets/images/imga.JPG"
import img2 from "../../assets/images/DSC04786.JPG"
import img3 from "../../assets/images/DSC04788.JPG"
import img4 from "../../assets/images/img_4.jpg"
import img5 from "../../assets/images/hustle and flow.jpg"
import img6 from "../../assets/images/img_1.png"
import img7 from "../../assets/images/vieser1.jpg"
import img9 from "../../assets/images/img_9.jpg"
import img10 from "../../assets/images/img_10.jpg"
import img11 from "../../assets/images/img_11.jpg"
import img12 from "../../assets/images/img_12.jpg"
import img13 from "../../assets/images/img_13.jpg"
import img14 from "../../assets/images/img_14.jpg"
import img15 from "../../assets/images/img_15.jpg"
import img16 from "../../assets/images/img_16.jpg"
import img17 from "../../assets/images/img_17.jpg"
import img18 from "../../assets/images/img_18.jpg"
import img19 from "../../assets/images/img_19.jpg"
import img20 from "../../assets/images/img_20.jpg"
import img21 from "../../assets/images/img_21.jpg"
import img22 from "../../assets/images/img_22.jpg"
import img23 from "../../assets/images/img_23.jpg"
import img24 from "../../assets/images/img_24.jpg"
import img25 from "../../assets/images/img_25.jpg"
import img26 from "../../assets/images/img_26.jpg"
import img27 from "../../assets/images/img_27.jpg"
import img28 from "../../assets/images/img_28.jpg"
import img29 from "../../assets/images/img_29.jpg"
import classes from "./styles.module.css"

const photos = [
      {
            src: img1,
            width: 2624,
            height: 3984,
      },
      {
            src: img2,
            width: 1624,
            height: 1936,
      },
      {
            src: img3,
            width: 2624,
            height: 3936,
      },
      {
            src: img4,
            width: 2624,
            height: 3936,
      }, {
            src:  img5,
            width: 2000,
            height: 2600,
      },
      {
            src: img6,
            width: 1600,
            height: 1600,
      },
      {
            src: img7,
            width: 1924,
            height: 1086,
      },
      {
            src: img9,
            width: 1600,
            height: 1200,
      },
      {
            src: img10,
            width: 1600,
            height: 1200,
      },
      {
            src: img11,
            width: 1600,
            height: 1200,
      },
      {
            src: img12,
            width: 1600,
            height: 1200,
      },
      {
            src: img13,
            width: 1600,
            height: 1200,
      },
      {
            src: img14,
            width: 1600,
            height: 1200,
      },
      {
            src: img15,
            width: 1600,
            height: 1200,
      },
      {
            src: img16,
            width: 1600,
            height: 1200,
      },
      {
            src: img17,
            width: 1200,
            height: 1600,
      },
      {
            src: img18,
            width: 1200,
            height: 1600,
      },
      {
            src: img19,
            width: 1200,
            height: 1600,
      },
      {
            src: img20,
            width: 1200,
            height: 1600,
      },
      {
            src: img21,
            width: 1200,
            height: 1600,
      },
      {
            src: img22,
            width: 1200,
            height: 1600,
      },
      {
            src: img23,
            width: 1200,
            height: 1600,
      },
      {
            src: img24,
            width: 1104,
            height: 1472,
      },
      {
            src: img25,
            width: 1104,
            height: 1472,
      },
      {
            src: img26,
            width: 1104,
            height: 1472,
      },
      {
            src: img27,
            width: 1104,
            height: 1472,
      },
      {
            src: img28,
            width: 1200,
            height: 1600,
      },
      {
            src: img29,
            width: 931,
            height: 1512,
      },
      // {
      //       src: "https://i.pinimg.com/474x/c6/fd/5f/c6fd5f521f3af710cea4659fa41bc4e3.jpg",
      //       width: 600,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/2c/76/6a/2c766a988657c5ae8438b44277cf4717.jpg",
      //       width: 1600,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/46/cc/a3/46cca33043868f1c6a70d203b745c7ec.jpg",
      //       width: 800,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/46/cc/a3/46cca33043868f1c6a70d203b745c7ec.jpg",
      //       width: 1600,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/ee/a9/db/eea9dbc941723c30616d1be594dcfc23.jpg",
      //       width: 1600,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/27/1a/13/271a13f53b5c819944040d97803fbe9f.jpg",
      //       width: 1600,
      //       height: 900,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/4e/48/63/4e4863b199e1d6f13cc1f07671db78d7.jpg",
      //       width: 400,
      //       height: 400,
      // },
      // {
      //       src: "https://i.pinimg.com/474x/29/1a/cb/291acb91857a78627fe2b41ddd3039f1.jpg",
      //       width: 1000,
      //       height: 900,
      // },
];
function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
    
      // While there remain elements to shuffle.
      while (currentIndex > 0) {
    
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }
export default function App() {
      // const [open, setOpen] = React.useState(false);
      const [album,setalbulm] = React.useState(shuffle(photos))

    React.useEffect(() => {
    
      window.scrollTo(0, 0)

    return () => {

    }
  }, [])


      const [index, setIndex] = React.useState(-1);


      return (
            <>

                 <div className={classes.container}>
                  <div className={classes.headText}>
                        <div>Gallery</div>
                  </div>
                        <div className={classes.albumContainer}>
                              <PhotoAlbum
                                    layout="masonry"
                                    photos={album}
                                    padding={0}
                                    onClick={({ index: current }) => setIndex(current)}
                              />

                              <Lightbox
                                    index={index}
                                    slides={album}
                                    open={index >= 0}
                                    close={() => setIndex(-1)}
                              />
                        </div>
                  </div>
            </>
      );
}