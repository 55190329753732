import img from "../../assets/images/tino.jpeg"
import "./about.css"
import img_4 from "../../assets/images/img_4.jpg"
export const AboutMe=()=>{
    const txt = {
        text:"WEISER whose real name is Ngwa Bruce Armel was born in Bamenda ( bambili ) on 12th Dec. He is a singer and he started his music career in 2002. Inspired by his elder brother Yimtchi Narcisse who was always singing and interpreting songs with him. He went to College Classique of Bonaberie a plumber by profession. He finally moved to Germany where he resides now. He lives in Germany precisely in Baden-Württemberg. His first time in studio was in 2003 when He wrote his first single titled( fou de toi and you'r the one 4 me ) which was on tape record and is no more relevant. Music has always been his soul comforter even though he had to take a long break because of too much responsibility but he is not relenting his efforts to move ahead of his dream. This is the reason why he presents his 9 track Project titled JOY & PAIN. A blend and mixture of both sides of the coin ie expressing emotions of Love, care, comfort and pain. Two great producers from Cameroon worked on the project",
        tit:"WELCOME TO MY SITE"
    }
    return(
        <div  style={{backgroundColor:"black",color:"whitesmoke",paddingBottom:"30px"}}>
            <div className="container" style={{textAlign:"center"}} >
            <h1>AboutMe</h1>
            <div className="main-section" style={{display:"flex",justifyContent:"center",gap:"30px"}}>
                <div >
                    <p style={{textAlign:"start"}}><span style={{color:"cyan"}}>{txt.tit}</span><br/><br/>

                       {txt.text}

                    </p>
                </div>
                <img src={img_4} width={"30%"}/>
            </div>

            </div>
            
        </div>
    )
}